
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import ProgramsContainer from "@/components/Programs";
import { applicationReducer } from "@Entities/Application/model/slice/applicationSlice";
import { getUserAcceptence } from "@Entities/User";
import { PersonalTabs } from "@Features/PersonalTabs";
import { IndexPage } from "@Pages/IndexPage";
import { DynamicModuleLoader, ReducersList, } from "@Shared/lib/DynamicModuleLoader";
import { PersonalMainLayout } from "@Widgets/PersonalMainLayout";
import { NextPage } from "next";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
const reducers: ReducersList = {
    applicationReducer: applicationReducer,
};
const Home: NextPage = () => {
    const acceptence = useSelector(getUserAcceptence);
    if (acceptence) {
        return (<DynamicModuleLoader reducers={reducers}>
        <IndexPage />
      </DynamicModuleLoader>);
    }
    return (<PersonalMainLayout>
      <PersonalTabs />
      <Container>
        <ProgramsContainer />
      </Container>
    </PersonalMainLayout>);
};
export default Home;

    async function __Next_Translate__getStaticProps__19243a59d41__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19243a59d41__ as getStaticProps }
  